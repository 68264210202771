import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { ThemeProvider } from '@mui/material/styles';
import reportWebVitals from './reportWebVitals';
import theme from './theme';
import CssBaseline from '@mui/material/CssBaseline';
import { Provider } from 'react-redux';
import { initializeStore } from './redux/store';
import './i8n';
import videoBackground from '../src/video4.mp4';

const store = initializeStore();

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      {/* <React.StrictMode> */}
      {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
      <CssBaseline />
      {/* Ne znam kakav ce ovaj suspense  uticati na ostali kod, ali se  moze setovati u i8n.js file na false */}
      <React.Suspense fallback={
        <div className="loading">
          <div className="loader"></div>
        </div>
      }>
        <video src={videoBackground} autoPlay muted>
        </video>
        <App />
      </React.Suspense>
      {/* </React.StrictMode> */}
    </ThemeProvider>
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
